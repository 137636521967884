
var USE_LOCAL_STORAGE = false; // in generell
var CHANGES_CACHE_VERSION = 1;
var USE_LOCAL_STORAGE_DATA_TYPE = 'local';

if(localStorage) {
	USE_LOCAL_STORAGE = true;

	try {
		localStorage['sp-storage'] = 1;
	} catch(e) {
		if(e.name === 'QUOTA_EXCEEDED_ERR') {
			try {
				window.localStorage.clear();
				localStorage['sp-storage'] = 1;
			} catch(e) {
				USE_LOCAL_STORAGE = false;
				if(typeof console == 'object')
					console.log("Local storage write failure - " + e);
			}
		} else {
			USE_LOCAL_STORAGE = false;
			if(typeof console == 'object')
				console.log("Local storage write failure - " + e);
		}
	}

	if(USE_LOCAL_STORAGE) {

		localStorage.removeItem('sp-storage');

		// check localStorage size
		var checkLSSize = function() {
			var lsBytes = 0;
			for (var i in localStorage) {
				//console.log(' localStorage.i ', typeof i, i,localStorage[i].length);
				lsBytes += localStorage[i].length;
			}
			console.log(' localStorage Bytes ', lsBytes);
		};
		// checkLSSize();
		// create and save a unique deviceId
		var checkCCV = function() {
			// if CHANGES_CACHE_VERSION is higher than the saved CHANGES_CACHE_VERSION
			// this resets the complete cache to be prepared for changes in cache handling
			var CCV =  localStorage['CHANGES_CACHE_VERSION'];
			if(!CCV) {
				window.localStorage.clear();
			}
			if(CHANGES_CACHE_VERSION
				&& CCV
				&& CCV < CHANGES_CACHE_VERSION) {
				window.localStorage.clear();
			}
			if(CHANGES_CACHE_VERSION) try {
				localStorage['CHANGES_CACHE_VERSION'] = CHANGES_CACHE_VERSION;
			} catch(e) {}
		};
		checkCCV();

	}
}


var STORE = {
	_save:function(k,t) { // db or local storage? use cookie as well?
		if(USE_LOCAL_STORAGE) {
			if(typeof t == 'object') {
				t = JSON.stringify(t);
			}
			try {
				window.localStorage[k] = t;
				//if(typeof console == 'object') console.log('x localStorage[k] '+k+': %o',window.localStorage[k]);
			} catch(e) {
				if(typeof console == 'object') console.log('x Not saved: '+k+': %o',e);
			}
			return true;
		}
		return false;
	},

	_get:function(k) {
		if(USE_LOCAL_STORAGE) {
			return window.localStorage[k];
		}
		return false;
	}
}