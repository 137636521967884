


function Local() {

	this.buttonOpacity = 0.25;
	this.initialized=false;
	this.modals = 'bootstrap'; // bPopup

	this.init = function()
	{
		if(this.initialized) return false;
		this.initialized=true;
		var me = this;

		this.initLazyLoadSections();
		this.minContentHeight();
		this.initAffix();

		if(window.mode && window.mode == 'admin') {
			$('.editme img').tooltip({});
			$('.anchor img').tooltip({});
			$('.admin-dot').tooltip({});
		}

		$('[data-toggle=tooltip]').tooltip({});
		$('[data-toggle=bottomtooltip]').tooltip({
			placement:'bottom'
		});
		$('[data-toggle=toptooltip]').tooltip({
			placement:'top'
		});

		$(window).on('resize',function(event){
			me.initAffix();
			me.minContentHeight();
			// me.checkStacktable();
		});

		this.hasStacktable = false;
		// this.stacktable();
	}

	this.initAffix = function() {

		var me = this;

		$('#mainnav').affix({
			offset: {
				top: function () {
					var selector = '.affix + .content';
					var style = 'margin-top: '+$('header#mainnav').outerHeight(true)+'px';
					me.insertRule(selector,style);
					return (this.top = $('header#mainnav').outerHeight(true) - 76)
				}
			}
		})
	};


	this.stacktable = function() {

		this.stacktablecW = $('.product-table').innerWidth();
		this.stacktabletW = $('.table-product').outerWidth();

		// only tables with more than 3 tds (description, unit, product)
		var checkTDs = $('.table-product tbody tr:first-child').children();

		if (checkTDs && checkTDs.length > 3) {
			$('.table-product').stacktable({myClass: 'mini-table',displayHeader: false,displayFirstRowHeader:false});
			this.hasStacktable = true;
			this.checkStacktable();
		}
	};

	this.checkStacktable = function() {

		if(!this.hasStacktable) {
			return false;
		}

		var cW = $('.product-table').innerWidth();
		var tW = this.stacktabletW;

		var tWc = $('.table-product.large-only').outerWidth();
		if(tWc < tW) {
			this.stacktabletW = tWc;
			tW = this.stacktabletW;
		}

		var diff = tW - cW;

		if(diff > 5) {
			$('.table-product.small-only').css({
				display:'table'
			});
			$('.table-product.large-only').css({
				display:'none'
			});
		} else {
			$('.table-product.small-only').css({
				display:'none'
			});
			$('.table-product.large-only').css({
				display:'table'
			});
		}
	};


	this.switchLang = function(lang_key) {
		var found = false;
		var url = document.URL;
		var langs = ['en','es','de','it','fr'];
		var curr_lang = "/" + lang_key + "/";
		for(lang in langs) {
			if (url.match(langs[lang])) {
				var found = url.replace(url,curr_lang);
				break;
			}
		}

		if(found) document.location.href=found;
	};

	this.minContentHeight = function() {
		//    min-height: calc(100vh - 700px);
		var hh = $('header#mainnav').height() || $('.topaddress').outerHeight()  || 0;
		var fh = $('footer .footer').outerHeight() || $('.bottomfooter').outerHeight() || 0;
		$('.content').css('min-height','calc(100vh - '+(hh+fh)+'px)');
	};



	this.openPDF = function(src) {

		window.open(src);
	};

	this.openModal = function(id,src) {
		$(id).modal('show');
	};


	this.showDiagrams = function (data, textStatus, jqXHR) {
		var modalWin = $('#modal-diagram');
		var html = '';

		for(var i=0;i<data.diagrams.length;i++) {
			var p = data.diagrams[i];

			html += '<div class="col-sm-12 col-md-6 col-lg-6">';
			html += '<h3>'+p.title+'</h3>';
			html += '<img'; //src ="'+p.src+'"
			html += ' data-img="'+p.src+'"';
			html += ' style="display: block;min-height:100px;"';
			html += ' class="lazySrc img-responsive"';
			html += ' />';
			html += '';
			html += '';
			html += '</div>';
		}


		modalWin.find('.modal-body').html(html);
		this.lazyLoadContent(modalWin);
	};

	/**
	 * Show modal window and load content
	 */
	this.modalContents = {
		'diagram': {
			url: '/api/v1/product/diagrams',
			dataType: 'json',
			callback: 'showDiagrams'
		}
	};

	this.loadModal = function(m,p) {

		var model = this.modalContents[m];
		var modalWin = $('#modal-'+m);
		var me = this;

		if(!model.initialized) {
			modalWin.modal({
				keyboard: false,
				show: false
			});
		}

		modalWin.on('shown.bs.modal', function () {
			if(!model.initialized) {
				$.ajax({
					type: "POST",
					url: model.url,
					data: p,
					success: function(data, textStatus, jqXHR) {
						if(model.dataType && model.dataType == 'html') {
							modalWin.find('.modal-body').html(data);
						} else if(typeof model.callback === 'function') {
							model.callback(data, textStatus, jqXHR);
						} else if(typeof model.callback === 'string') {
							me[model.callback](data, textStatus, jqXHR);
						}
					},
					dataType: model.dataType || 'json'
				});
				model.initialized = true;
			}
		});

		modalWin.modal('show')
	};

	/**
	 * Helper: select content in textfield
	 */
	this.selectOnFocus = function() {
		$('.selectonfocus').on('focus',function(e) {
			$(this).select();
		});
		$('.selectonfocus').on('click',function(e) {
			$(this).select();
		});
	};

	/**
	 * why don´t create a new stylesheet?
	 * @returns {*}
	 */
	this.getLastStylesheet = function () {
		if (this.styleSheet) return this.styleSheet;

		// create a stylesheet
		var styleTag = document.createElement("style");
		var head = document.getElementsByTagName("head")[0];
		head.appendChild(styleTag);

		this.styleSheet = styleTag.sheet ? styleTag.sheet : styleTag.styleSheet;
		return this.styleSheet;
	}

	/**
	 *
	 * @param selector
	 * @param style
	 * @param idx deprecated
	 */
	this.insertRule = function(selector,style,idx) {
		try {
			if(!this.styleSheet) this.styleSheet = this.getLastStylesheet();
			if (this.styleSheet.insertRule) {   // all browsers, except IE before version 9
				this.styleSheet.insertRule (selector+" {"+style+"}", this.styleSheet.cssRules.length);
			}
			else if (this.styleSheet.addRule) {  // Internet Explorer before version 9
				//if(typeof console == 'object') console.log('x this.styleSheet.rules.length '+ this.styleSheet.rules.length+': %o',this.styleSheet.rules.length);
				this.styleSheet.addRule(selector, style, this.styleSheet.rules.length);
			}
		} catch(e) {
			if(typeof console == 'object') console.log('Unsupported insertRule / addRule',e);
		}
	};

	this.initLazyLoadSections = function() {

		var sections = $('section');
		this.sections = [];
		for(var i = 0;i < sections.length;i++) {
			this.sections[i] = {
				section: $(sections[i]),
				id: sections[i].id,
				loaded: false
			};
		}

		$(window).scroll(function(e) {
			LC.lazyLoadSections();
		});
		this.lazyLoadSections();
	}

	this.lazyLoadSections = function() {
		var wheight = $(window).height();
		var scrollTop = $(window).scrollTop();
		for(var i = 0;i < LC.sections.length;i++) {
			var offset = LC.sections[i].section.offset();

			if((!(LC.sections[i].loaded) && (wheight+scrollTop) >= offset.top)) {
				LC.sections[i].loaded = true;
				LC.lazyLoadContent(LC.sections[i].section);
			}
		}
	}

	/**
	 * Lazy load content
	 *
	 * @param p
	 */
	this.lazyLoadContent = function (p) {

		var src,xImg,i,dataLoad;

		// lazy load bg images
		var loadBgImg = function(el) {
			jel = $(el);
			var src = jel.attr('data-img');
			if (src && src.length > 0) {
				var i = new Image();
				i.src = src;
				i.onload = function () {
					jel = $(el);
					jel.removeClass('lazyBg');
					jel.css('backgroundImage','url('+src+')');
					jel.removeAttr('data-img');
				};
			}
		};

		var els = p.find('.lazyBg');
		if(p.hasClass('lazyBg')) {
			els.push(p);
		}
		var els_length = els.length;
		for (imidx = 0; imidx < els_length; imidx++) {
			var el = els[imidx];
			loadBgImg(el);
		}

		var loadImg = function(img,p) {
			var jimg = $(img);
			var src = jimg.attr('data-img');
			if (src && src.length > 0) {
				i = new Image();
				i.src = src;
				i.onload = function () {
					jimg.attr('src', src);
					jimg.removeClass('lazySrc');
					jimg.removeAttr('data-img');
				};
			}
		};

		// lazy load images
		var imgs = p.find('img.lazySrc');
		var img_length = imgs.length;
		for (var imidx = 0; imidx < img_length; imidx++) {
			var img = imgs[imidx];
			loadImg(img,p);
		}
	}

	//this.unCheckRadios = function(t)
	//{
	//	var xt = Ext.get(t);
	//	if(xt.getAttribute('type') == 'radio' && xt.dom.checked == true) {
	//		var n = xt.getAttribute('name');
	//		if(LC.selectedInputs[n] == xt.dom.id) {
	//			xt.dom.checked = false;
	//			LC.selectedInputs[n] = false;
	//		} else {
	//			LC.selectedInputs[n] = xt.dom.id;
	//		}
	//	}
	//}


	//---------------------------------------------------------------------------------------

	//this.checkForm = function()
	//{
	//
	//	this.error = false;
	//	this.radioError = true;
	//
	//	this.params = new Object();
	//	var ipts = Ext.DomQuery.select('#localsubmitform input');
	//	Ext.each(ipts,function(item) {
	//		Eitem = Ext.get(item);
	//		switch(item.type) {
	//			case'checkbox':
	//				if(item.checked == true) {
	//					this.params[item.name] = this.URLEncode(item.value);
	//				}
	//				if(Eitem.hasClass('req') && item.checked != true) {
	//					var label = Ext.get('label_'+item.id);
	//					if(label) label.addClass('labelerror');
	//					this.error = true;
	//				} else {
	//					var label = Ext.get('label_'+item.id);
	//					if(label) label.removeClass('labelerror');
	//				}
	//				break;
	//			case'radio':
	//				if(item.checked == true) {
	//					this.params[item.name] = this.URLEncode(item.value);
	//					this.radioError = false;
	//				}
	//				break;
	//			//default:
	//			case'hidden':
	//			case'text':
	//				this.params[item.name] = this.URLEncode(item.value);
	//				if(Eitem.hasClass('req') && item.value.length == 0) {
	//					var label = Ext.get('label_'+item.id);
	//					if(label) label.addClass('labelerror');
	//					this.error = true;
	//				} else {
	//					var label = Ext.get('label_'+item.id);
	//					if(label) label.removeClass('labelerror');
	//				}
	//		}
	//	},this);
	//
	//	if((!this.radioError) && (!this.error)) {
	//		this.setOpacity('submitbutton',1);
	//		return true;
	//	}
	//
	//	this.setOpacity('submitbutton',this.buttonOpacity);
	//	return false;
	//
	//}

	//---------------------------------------------------------------------------------------

	this.URLEncode = function(s) {
		return encodeURIComponent(s);
	}

	this.check4Return = function(event) {
		var obj = event.target || event.srcElement;
		if(obj.type != "textarea" && event && event.keyCode == 13 ){
			if ( event.preventDefault )
				event.preventDefault()
			else event.returnValue = false;
		};
	}

	//---------------------------------------------------------------------------------------
	//this.mask = function(f,p) {
	//	var d = {loading:true,opacity:.75};
	//	if(typeof p != 'object') var p = {};
	//	p = Ext.apply(d,p);
	//	f.setStyle('position','relative');
	//	var dh = Ext.DomHelper;
	//	var spec = {
	//		tag: 'div',
	//		cls: 'mask',
	//		style: 'width: 10px;',
	//		id:'loaderwr'+f.id
	//	};
	//
	//	if(p.loading) {
	//		spec.children = [
	//			{tag: 'div', cls: 'loader', id:'loadermgs'+f.id,
	//				children: [
	//					{tag: 'img', cls: 'loader-img', src:'/mcms/lehmann/_img/ajax-loader-arrows.gif'}
	//				]
	//			}
	//		];
	//	}
	//	if(p.content) spec.children = p.content;
	//
	//	var mask = dh.insertFirst(f,spec,true);
	//	this.setOpacity(mask.id,p.opacity);
	//
	//	if(typeof p.bwidth != 'number') p.bwidth = 0;
	//	if(typeof p.bheight != 'number') p.bheight = 0;
	//	mask.setStyle({
	//		width: (f.getWidth()-p.bwidth)+'px',
	//		height: (f.getHeight()-p.bheight)+'px',
	//		display:'block'
	//	});
	//	return mask;
	//}


	this.apply = function(o, c){
		if(o && c && typeof c == 'object'){
			for(var p in c){
				o[p] = c[p];
			}
		}
		return o;
	}


	// Backend functions
	//---------------------------------------------------------------------------------------

	// this.newProduct = function(form,action) {
	//
	// 	Ext.getCmp(form.p_id).has_been_submitted = true;
	// 	var data = action.result.metaData.data;
	// 	var sid = false;
	// 	for(i=0;i<data.length;i++) {
	// 		if(data[i]['key'] == 'sid') var sid = data[i]['value'];
	// 	}
	//
	// 	if(sid && sid.length >0) {
	//
	// 		PHPOS.addTab($L('hmproduct'),'hmproductlist'+sid,'x-cart-ico','xhmproducteditor',{
	// 			content_key:'xhmproducteditor',
	// 			product_sid:sid,
	// 			sid:sid
	// 		},{autoScroll:false,content_key:'xhmproducteditor',sid:sid});
	//
	// 		if(typeof form.win_id != 'undefined') Ext.getCmp(form.win_id).close();
	// 	}
	//
	// }
	//
	// this.duplicateProduct = function(form,tid,sid) {
	//
	// 	var ed = Ext.get(tid);
	// 	ed.mask($L('Duplicating Record...'));
	//
	// 	var fields = form.getValues();
	// 	fields.noxml = true;
	// 	fields.triggers = 'ajaxduplicatehmproduct';
	//
	// 	var url = '/ajax/dotrigger/hmproduct/'+sid;
	// 	Ext.Ajax.request({
	// 		scope:this,
	// 		method:'post',
	// 		url: url,
	// 		tid:tid,
	// 		success: function(response,options){
	// 			Ext.get(options.tid).unmask();
	// 			var rData = Ext.util.JSON.decode(response.responseText);//passed back from server
	// 			if(rData.product_sid) {
	// 				var product_sid = rData.product_sid;
	// 				PHPOS.addTab($L('hmproduct'),'hmproductlist'+product_sid,'x-cart-ico','xhmproducteditor',{
	// 					content_key:'xhmproducteditor',
	// 					product_sid:product_sid,
	// 					sid:product_sid
	// 				},{autoScroll:false,content_key:'xhmproducteditor',sid:product_sid});
	// 			} else Ext.Msg.alert("Error", rData.errorMsg);
	//
	// 		},
	// 		failure: function(response,options){
	// 		},
	// 		params: fields
	// 	});
	//
	//
	// }

}

LC = new Local();

$( document ).ready(function() {
	LC.init();
});
